<template>
  <div
    @mouseenter="onEnterNavigation()"
    @mouseleave="onLeaveNavigation()"
  >
    <b-navbar toggleable="md" type="default">
      <b-navbar-brand to="/">
        <img src="favicon.png" alt="Candlely">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <i class="fas fa-bars"></i>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>

          <template v-for="(nav, index) in navigationList">
            <b-nav-item-dropdown
              :ref="`dropdown_${index}`"
              v-if="nav.children && checkAdmin(nav)"
              :key="nav.title"
              :class="{ 'router-link-active-parent': isParentActive(nav) }"
              @mouseenter.native="onEnterDropdown(`dropdown_${index}`)"
              @click="onDropdownClick()"
            >
              <template #button-content>
                {{ nav.title }}
              </template>

              <template v-for="child in nav.children">
                <b-nav-item
                  v-if="checkAdmin(child)"
                  :key="`${nav.title}_${child.title}`"
                  :to="child.path"
                  :disabled="child.disabled"
                  @click="onNavigationClick()"
                >
                  <span>{{ child.title }}</span>
                </b-nav-item>
              </template>

            </b-nav-item-dropdown>
            <b-nav-item
              v-else-if="checkAdmin(nav)"
              :key="nav.title"
              :to="nav.path"
              :disabled="nav.disabled"
              @click="onNavigationClick()"
            >
              <span>{{ nav.title }}</span>
            </b-nav-item>
          </template>

        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            ref="dropdown_account"
            right
            @mouseenter.native="showDropdown('dropdown_account')"
          >
            <template #button-content>
              {{ account.display_name }}
            </template>

            <b-nav-item
              v-if="isAdmin()"
              to="/developer"
              @click="onNavigationClick()"
            >
              Developer
            </b-nav-item>

            <b-nav-item
              v-if="isAdmin()"
              to="/admin"
              @click="onNavigationClick()"
              disabled
            >
              Admin
            </b-nav-item>

            <b-nav-item
              to="/signout"
              @click="onNavigationClick()"
            >
              Sign out
            </b-nav-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import navigationList from './NavigationList';

export default {
  name: 'Navigation',
  components: {},
  computed: {
    AccountName() {
      return this.account.login;
    },
  },
  data() {
    return {
      account: this.$store.state.account,
      navigationList,
      navigationTimer: null,
      navigationChanger: {
        delay: 3000,
        timer: null,
      },
      dropdownChanger: {
        name: '',
        shown: '',
        delay: 250,
        timer: null,
      },
    };
  },
  methods: {
    checkAdmin(navigationItem) {
      if (navigationItem.admin !== true) {
        return true;
      }
      return this.isAdmin();
    },
    isParentActive(parent) {
      return parent.children.map((child) => child.path).includes(this.$route.path);
    },
    onNavigationClick() {
      this.hideNavigation();
    },
    onDropdownClick() {
      this.cancelDropdownTimer();
    },
    onEnterNavigation() {
      this.cancelNavigationTimer();
    },
    onLeaveNavigation() {
      this.startNavigationTimer();
    },
    startNavigationTimer() {
      if (this.navigationChanger.timer) {
        this.cancelNavigationTimer();
      }
      this.navigationChanger.timer = setTimeout(() => {
        this.hideNavigation();
      }, this.navigationChanger.delay);
    },
    cancelNavigationTimer() {
      clearTimeout(this.navigationChanger.timer);
      this.navigationChanger.timer = null;
    },
    getActiveDropdown() {
      const dropdowns = [].slice.call(document.querySelectorAll('.dropdown-menu.show'));
      /* eslint no-underscore-dangle: 0 */
      return dropdowns.length > 0 ? dropdowns[0].parentElement.__vue__ : null;
    },
    getDropdown(dropdownName) {
      const ref = this.$refs[dropdownName];
      return Array.isArray(ref) ? ref[0] : ref;
    },
    showDropdown(dropdownName) {
      const dropdown = this.getDropdown(dropdownName);
      dropdown.show();
      this.dropdownChanger.shown = dropdownName;
    },
    startDropdownTimer(dropdownName) {
      if (this.dropdownChanger.timer) {
        this.cancelDropdownTimer();
      }
      if (this.dropdownChanger.shown === dropdownName) {
        return;
      }
      this.dropdownChanger.name = dropdownName;
      this.dropdownChanger.timer = setTimeout(() => {
        this.showDropdown(dropdownName);
      }, this.dropdownChanger.delay);
    },
    cancelDropdownTimer() {
      clearTimeout(this.dropdownChanger.timer);
      this.dropdownChanger.timer = null;
    },
    onEnterDropdown(dropdownName) {
      const activeDropdown = this.getActiveDropdown();
      if (activeDropdown) {
        this.startDropdownTimer(dropdownName);
      } else {
        this.showDropdown(dropdownName);
      }
    },
    hideNavigation() {
      this.cancelNavigationTimer();
      this.cancelDropdownTimer();
      const activeDropdown = this.getActiveDropdown();
      if (activeDropdown) {
        activeDropdown.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 8px 36px;
}

.navbar-header {
  width: 100%;
}

.b-nav-dropdown::v-deep {
  & .dropdown-menu-right {
    left: -8px !important;
  }

  & .dropdown-toggle::after {
    vertical-align: middle;
  }
}

.navbar-nav::v-deep .router-link-active {
  font-weight: bold;
}
</style>
